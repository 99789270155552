import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Register from "./../screens/Register";
import Home from "./../screens/Home";
import BasicProfile from "./../screens/profile/BasicProfile";
import BankingProfile from "./../screens/profile/BankingProfile";
import ChangePassword from "./../screens/profile/ChangePassword";
import Subscription from "./../screens/Subscription";
import Logout from "./../screens/Logout";
import Subscriptions from "./../screens/Subscriptions";
import ExpiredPolicy from "./../screens/ExpiredPolicy";
import ExpiringSoonPolicy from "./../screens/ExpiringSoonPolicy";
import Application from "./../screens/Application";
import KYCUpdate from "./../screens/KYCUpdate";
// import AccessTokens from './../screens/profile/AccessTokens';
import AdminDashboard from "./../screens/admin/AdminDashboard";
import { connect } from "react-redux";
import AdminResellers from "../screens/admin/AdminResellers";
import AddMLM from "../screens/admin/AddMLM";
import AddMFI from "../screens/admin/AddMFI";
import AddEmployee from "../screens/admin/AddEmployee";
import AdminResellerProducts from "../screens/admin/AdminResellerProducts";
import AdminResellerReports from "../screens/admin/AdminResellerReports";
import AddCorporate from "../screens/admin/AddCorporate";
import ClaimForm from "../screens/ClaimForm";
import ProductDetail from "../screens/ProductDetail";
import ClaimsRewamp from "../screens/rewampClaims";
import Leads from "../screens/Leads";
import Employees from "../screens/mlm/Employee";
import Terms from "../screens/Terms";
import Report from "../screens/reports/Report";
import SalesReport from "../screens/reports/SalesReport";
import EmployeeReport from "../screens/reports/EmployeeReport";
import ResellerReport from "../screens/reports/ResellerReport";
import ExcelUploader from "../screens/excelUploader/Index";
import PolicyUploader from "../screens/policyUploader/Index";
import EmiExcelUploader from "../screens/emiUploader/Index";
import Roles from "../screens/Roles";
import PasswordlessLogin from "../screens/PasswordlessLogin";
import ComissionReport from "../screens/reports/ComissionReport";
import BusinessReport from "../screens/reports/BusinessReport";
import AddRole from "../screens/admin/AddRole";
import ResellerClaimReport from "../screens/reports/ResellerClaimReport";
import LeadCapture from "../screens/leadCapture/CustomerLeadCapture";
import SubscriptionReport from "../screens/reports/SubscriptionReport";
import LeadCaptureReport from "../screens/reports/CustomerLeadCaptureReport";
import ResellerLeadCapture from "../screens/leadCapture/ResellerLeadCapture";
import ResellerLeadCaptureReport from "../screens/reports/ResellerLeadCaptureReport";
import AddProducts from "../screens/addProduct/AddProducts";
import CloneProducts from "../screens/addProduct/CloneProducts";
import DueComissionReport from "../screens/reports/DueComissionReport";
import ResellerDataReport from "../screens/reports/ResellerDataReport";
import Report360 from "../screens/reports/report360/index.js";
import SubscriptionPreview from "../screens/SubscriptionPreview";
import FinalView from "../screens/preview/FinalView";
import ResellerUploadDateReport from "../screens/reports/ResellerUploadDateReport";
import ResellerUploadDateTeamReport from "../screens/reports/ResellerUploadDateTeamReport";
import ProductWiseSalesReport from "../screens/reports/ProductWiseSalesReport";
import ResellerProductWiseSalesReport from "../screens/reports/ResellerProductWiseSalesReport";
import CreditLifeExcelUploader from "../screens/creditLifeUploader/Index";
import ResellerTeamDataReportFilter from "../screens/reports/ResellerTeamDataReportFilter";
import ResellerAppointmentReport from "../screens/reports/ResellerAppointmentReport";
import PolicyExpireReport from "../screens/reports/PolicyExpireReport";
import HomePageChemist from "../screens/chemist/HomePageChemist";
import CustomerInformation from "../screens/chemist/CustomerInformation";
import Appointment from "../screens/appointments";
import UpdateCustomerInfo from "../screens/chemist/UpdateCustomerInfo";
import ResellerDocUpload from "../screens/resellerDocUpload/index";
import SalesDashboard from "../screens/salesDashboard";
import PreviousSalesDashboard from "../screens/PreviousSalesDashboard";
import LevelSalesReport from "../screens/reports/LevelSalesReport.js";
import SalesResellerReport from "../screens/reports/SalesResellerReport/index.js";
import HierarchySalesReport from "../screens/reports/HierarchySalesReport.js";
import GroupExcelUploader from "../screens/groupExcelUploader/Index";
import ServiceabilityReports from "../screens/reports/ServiceabilityReports/index.js";
import InventoryConnect from "../screens/InventoryGroup/Index.js";
import PurchaseStock from "../screens/InventoryStock/Index.js";
import TransferStock from "../screens/InventoryStock/Index.js";
import MedicineInventoryDetails from "../screens/MedicineInventoryDetails/Index.js";
import InventoryMain from "../screens/Inventory/Inventory.js";
import InsuranceResellerView from "../screens/reports/insuranceResellerView/InsuranceResellerView.js";
import HealthCamp from "../screens/heathCamp/index.js";
import ReportMaster from "../screens/reports/ReportMaster/index.js";
import { PURCHASE_STOCK, TRANSFER_STOCK } from "./constants.js";
import ExcelUploadPolicyReport from "../components/excelPolicyUploadReport/index.js";
import ClaimsDetail from "../screens/claimsDetail/index.js";
import AdminUpload from "../screens/admin/adminUploader/AdminUpload.js";
import ActionClaimReport from "../screens/reports/ActionClaimReport.js";
import DownloadCenter from "../screens/DownloadReportsCenter/DownloadCenter.js";
export class AppRoutes extends Component {
  render() {
    const { permissions } = this.props.user;
    if (permissions?.hc_view) {
      return (
        <Switch>
          <Route path="/" exact={true}>
            <HealthCamp />
          </Route>
          <Route path="/profile">
            <BasicProfile />
          </Route>
          <Route path="/banking">
            <BankingProfile />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>
        </Switch>
      );
    } else if (this.props.user.is_admin) {
      return (
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>

          <Route path="/" exact={true}>
            <AdminDashboard />
          </Route>

          <Route path="/resellers">
            <AdminResellers />
          </Route>
          <Route path="/reseller/:resellerId">
            <AdminResellers />
          </Route>

          <Route path="/reseller-products">
            <AdminResellerProducts />
          </Route>

          <Route path="/reseller-reports">
            <AdminResellerReports />
          </Route>

          <Route path="/add-mlm">
            <AddMLM />
          </Route>

          <Route path="/add-mfi">
            <AddMFI />
          </Route>

          <Route path="/add-corporate">
            <AddCorporate />
          </Route>

          <Route path="/roles">
            <Roles />
          </Route>

          <Route path="/add-product">
            <AddProducts />
          </Route>
          <Route path="/clone-product">
            <CloneProducts />
          </Route>
          <Route path="/add-role">
            <AddRole />
          </Route>
          <Route path="/excel-uploader">
            <ExcelUploader />
          </Route>
          <Route path="/group-excel-uploader">
            <GroupExcelUploader />
          </Route>
          <Route path="/credit-life-uploader">
            <CreditLifeExcelUploader />
          </Route>
          <Route path="/admin-uploader">
            <AdminUpload />
          </Route>
          <Route path="/excel-history">
            <ExcelUploadPolicyReport />
          </Route>
          <Route
            path="*"
            render={() => {
              return <Redirect to="/" />;
            }}
          />
        </Switch>
      );
    } else
      return (
        <Switch>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/profile">
            <BasicProfile />
          </Route>
          <Route path="/banking">
            <BankingProfile />
          </Route>
          <Route path="/sales-dashboard">
            <SalesDashboard />
          </Route>
          <Route path="/previous-dashboard">
            <PreviousSalesDashboard />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>
          {/* <Route path="/manage-tokens">
      );
    }else
      return (
        <Switch>
          <Route path="/register">
            <Register />
          </Route>
         
          <Route path="/profile">
            <BasicProfile />
          </Route>
          <Route path="/banking">
            <BankingProfile />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>
          {/* <Route path="/manage-tokens">
               <AccessTokens />
            </Route> */}
          <Route path="/chemist">
            <HomePageChemist />
          </Route>
          <Route path="/customer-info">
            <CustomerInformation />
          </Route>
          <Route exact path="/subscription/:appId">
            <Application />
          </Route>
          <Route path="/update-kyc/:appId">
            <KYCUpdate />
          </Route>
          <Route path="/subscribe">
            <Subscription />
          </Route>

          <Route path="/excel-uploader">
            <ExcelUploader />
          </Route>
          <Route path="/group-excel-uploader">
            <GroupExcelUploader />
          </Route>

          <Route path="/policy-uploader">
            <PolicyUploader />
          </Route>
          <Route path="/credit-life-uploader">
            <CreditLifeExcelUploader />
          </Route>
          <Route path="/emi-uploader">
            <EmiExcelUploader />
          </Route>
          <Route path="/lead-capture">
            <LeadCapture />
          </Route>
          <Route path="/reseller-lead-capture">
            <ResellerLeadCapture />
          </Route>
          <Route path="/reports" exact={true}>
            <Report />
          </Route>
          <Route path="/reports/sales">
            <SalesReport />
          </Route>
          <Route path="/reports/policy-expire">
            <PolicyExpireReport />
          </Route>
          <Route path="/reports/emp-sales">
            <EmployeeReport />
          </Route>
          <Route path="/reports/reseller-sales">
            <ResellerReport />
          </Route>
          <Route path="/reports/commissions">
            <ComissionReport />
          </Route>
          <Route path="/reports/due-commissions">
            <DueComissionReport />
          </Route>
          <Route path="/reports/data-report">
            <ResellerDataReport />
          </Route>
          <Route path="/reports/upload-date-report">
            <ResellerUploadDateReport />
          </Route>
          <Route path="/reports/upload-date-team-report">
            <ResellerUploadDateTeamReport />
          </Route>
          <Route path="/reports/subscription-source-report">
            <ResellerTeamDataReportFilter />
          </Route>
          <Route path="/reports/product-wise-sales-report">
            <ProductWiseSalesReport />
          </Route>
          <Route path="/reports/reseller-product-wise-sales-report">
            <ResellerProductWiseSalesReport />
          </Route>
          <Route path="/reports/business">
            <BusinessReport />
          </Route>
          <Route path="/reports/business/:id">
            <BusinessReport />
          </Route>
          <Route path="/reports/reimbursements">
            <ResellerClaimReport />
          </Route>
          <Route path="/reports/subscriptions">
            <SubscriptionReport />
          </Route>
          <Route path="/reports/lead-capture">
            <LeadCaptureReport />
          </Route>
          <Route path="/reports/reseller-lead-capture">
            <ResellerLeadCaptureReport />
          </Route>
          <Route path="/reports/reseller-appointment">
            <ResellerAppointmentReport />
          </Route>
          <Route path="/reports/update-customer-info">
            <UpdateCustomerInfo />
          </Route>
          <Route path="/reports/report360">
            <Report360 />
          </Route>
          <Route path="/subscriptions">
            <Subscriptions />
          </Route>
          <Route path="/expired">
            <ExpiredPolicy />
          </Route>
          <Route path="/expiring-soon">
            <ExpiringSoonPolicy />
          </Route>
          <Route path="/subscription/preview/:id">
            <SubscriptionPreview />
          </Route>
          <Route path="/final-view/:id">
            <FinalView />
          </Route>
          <Route path="/leads">
            <Leads />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route exact path="/add-employee">
            <AddEmployee />
          </Route>
          <Route path="/employees">
            <Employees />
          </Route>
          <Route path="/employee/:id">
            <Employees />
          </Route>
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route exact={true} path="/product/:productId">
            <ProductDetail />
          </Route>
          <Route path="/reimbursement/:memberId/:policyId" exact={true}>
            <ClaimForm />
          </Route>
          <Route path="/reimbursementEdit/:claimId/:subClaimId">
            <ClaimForm />
          </Route>
          <Route path="/reimbursements" exact={true}>
            <ClaimsRewamp />
          </Route>
          <Route path="/reimbursements/:claimId">
            <ClaimsDetail />
          </Route>
          <Route path="/roles">
            <Roles></Roles>
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/auth/:token">
            <PasswordlessLogin />
          </Route>
          <Route path="/appointment">
            <Appointment />
          </Route>
          <Route path="/inventory">
            <InventoryConnect />
          </Route>
          <Route path="/inventory-stock-details">
            <InventoryMain />
          </Route>
          <Route path="/inventory-purchase">
            <PurchaseStock component={PURCHASE_STOCK} />
          </Route>
          <Route path="/inventory-Transfer">
            <TransferStock component={TRANSFER_STOCK} />
          </Route>
          <Route path="/inventory-details">
            <MedicineInventoryDetails component={TRANSFER_STOCK} />
          </Route>
          {/* <Route path="/active-session">
          <ActiveSession />
        </Route> */}
          <Route path="/reseller-document-upload">
            <ResellerDocUpload />
          </Route>
          <Route path="/reports/level-sales-report">
            <LevelSalesReport />
          </Route>
          <Route path="/reports/reseller-sales-report">
            <SalesResellerReport />
          </Route>
          <Route path="/reports/hierarchy-sales-report">
            <HierarchySalesReport />
          </Route>
          <Route path="/reports/hierarchy-sales-report/:id">
            <HierarchySalesReport />
          </Route>
          <Route path="/serviceability-report">
            <ServiceabilityReports />
          </Route>
          <Route path="/reports/insurance-view">
            <InsuranceResellerView />
          </Route>
          <Route path="/reports/report-master">
            <ReportMaster />
          </Route>
          {permissions?.excel_uploader_history_table && (
            <Route path="/excel-history">
              <ExcelUploadPolicyReport />
            </Route>
          )}
          {permissions?.action_claim_report && (
            <Route path="/reports/action-claim-report">
              <ActionClaimReport />
            </Route>
          )}
          {permissions?.download_archive && (
            <Route path="/download-center" exact={true}>
              <DownloadCenter />
            </Route>
          )}
          <Route
            path="*"
            render={() => {
              return <Redirect to="/" />;
            }}
          />
        </Switch>
      );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <AppRoutes {...props} />;
});
