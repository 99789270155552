import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppCard, AppHeading, AppRow } from "../components/AppComponents";
import Button from "../components/Button";
import ProductsList from "../components/home/ProductsList";
import Main from "./Main";
import insurance from "./../insurance.svg";
import shield from "./../shield.svg";
import { AiqaIcon } from "../components/AppIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faRupeeSign,
  faUserAstronaut,
  faUserFriends,
  faUserMd,
  faFileExcel,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { enrollmentGetApi, GetUserDashboardData } from "../lib/AppHelper";
import AiqaCenter from "./aiqaCenter";
import { checkReportPermission } from "../utils/constants";
import inventoryImg from "../images/Layer_1.png";
import UniversalSearch from "./admin/components/UniversalSearch";

export class Home extends Component {
  MessageRef = React.createRef();

  state = {
    teamCount: 0,
    salesAmount: 0,
    claimCount: 0,
    unsoldPolicy: 0,
    activePolicy: 0,
    expirePolicy: 0,
    expiringSoonPolicy: 0,
    activePolicyCurrentMonth: 0,
    totalSalesCurrentMonth: 0,
    ThisFinancialYear: "",
    activePolicyThisFinancialYear: 0,
    totalSalesThisFinancialYear: 0,
    teamCountLoader: false,
    salesAmountLoader: false,
    claimCountLoader: false,
    unsoldPolicyLoader: false,
    activePolicyLoader: false,
    expirePolicyLoader: false,
    expiringSoonPolicyLoader: false,
    activePolicyCurrentMonthLoader: false,
    activePolicyThisFinancialYearLoader: false,
  };

  componentDidMount() {
    const { user } = this.props;
    this.getTeamCount();
    this.getSalesAmount();
    this.getClaimCount();
    this.getUnsoldPolicy();
    this.getActivePolicy();
    this.getExpirePolicy();
    this.getExpiringSoonPolicy();
    if (user?.permissions?.sale_month_year_wise) {
      this.getActivePolicyThisMonthDetails();
      this.getActivePolicyThisFinancialYearDetails();
    }
  }

  getTeamCount = async () => {
    this.setState({ teamCountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/teamCount"
    );
    if (response.code === 200) {
      this.setState({ teamCount: response?.responseObject });
      this.setState({ teamCountLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ teamCountLoader: false });
    }
  };

  getSalesAmount = async () => {
    this.setState({ salesAmountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getSaleAmount"
    );
    if (response.code === 200) {
      this.setState({ salesAmount: response?.responseObject });
      this.setState({ salesAmountLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ salesAmountLoader: false });
    }
  };

  getClaimCount = async () => {
    this.setState({ claimCountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/claimCount"
    );
    if (response.code === 200) {
      this.setState({ claimCount: response?.responseObject });
      this.setState({ claimCountLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ claimCountLoader: false });
    }
  };

  getUnsoldPolicy = async () => {
    this.setState({ unsoldPolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getUnsoldPolicy"
    );
    if (response.code === 200) {
      this.setState({ unsoldPolicy: response?.responseObject });
      this.setState({ unsoldPolicyLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ unsoldPolicyLoader: false });
    }
  };

  getActivePolicy = async () => {
    this.setState({ activePolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getActivePolicy"
    );
    if (response.code === 200) {
      this.setState({ activePolicy: response?.responseObject });
      this.setState({ activePolicyLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ activePolicyLoader: false });
    }
  };

  getActivePolicyThisMonthDetails = async () => {
    const { user } = this.props;
    this.setState({ activePolicyCurrentMonthLoader: true });
    const response = await enrollmentGetApi(
      `enrollment-service/stats_queries/api/current_month_policy/${user?.id}`
    );
    if (response.code === 200) {
      this.setState({
        activePolicyCurrentMonth: response?.responseObject?.policyCount,
        totalSalesCurrentMonth: response?.responseObject?.policyAmount,
        activePolicyCurrentMonthLoader: false,
      });
    } else {
      this.MessageRef?.current?.show(response.message);
      this.setState({ activePolicyCurrentMonthLoader: false });
    }
  };

  getActivePolicyThisFinancialYearDetails = async () => {
    const { user } = this.props;
    this.setState({ activePolicyThisFinancialYearLoader: true });
    const response = await enrollmentGetApi(
      `enrollment-service/stats_queries/api/current_financial_year_policy/${user?.id}`
    );
    if (response.code === 200) {
      this.setState({
        activePolicyThisFinancialYear: response?.responseObject?.policyCount,
        totalSalesThisFinancialYear: response?.responseObject?.policyAmount,
        ThisFinancialYear: response?.responseObject?.year,
        activePolicyThisFinancialYearLoader: false,
      });
    } else {
      this.MessageRef?.current?.show(response.message);
      this.setState({ activePolicyThisFinancialYearLoader: false });
    }
  };

  getExpirePolicy = async () => {
    this.setState({ expirePolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/expirePolicy"
    );
    if (response.code === 200) {
      this.setState({ expirePolicy: response?.responseObject });
      this.setState({ expirePolicyLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ expirePolicyLoader: false });
    }
  };

  getExpiringSoonPolicy = async () => {
    this.setState({ expiringSoonPolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/expiringSoonPolicy"
    );
    if (response.code === 200) {
      this.setState({ expiringSoonPolicy: response?.responseObject });
      this.setState({ expiringSoonPolicyLoader: false });
    } else {
      this.MessageRef.current.show(response?.message);
      this.setState({ expiringSoonPolicyLoader: false });
    }
  };

  getNumber = function (number) {
    let amount = number;
    if (number >= 10000000) {
      amount = (number / 10000000).toFixed(2) + " Cr";
    } else if (number >= 100000) {
      amount = (number / 100000).toFixed(2) + " Lakh";
    } else {
      amount = new Intl.NumberFormat("en-IN").format(number || 0);
    }
    return amount.toString();
  };

  render() {
    const { user } = this.props;
    const { permissions, products } = user;
    const {
      teamCount,
      salesAmount,
      claimCount,
      unsoldPolicy,
      activePolicy,
      expirePolicy,
      expiringSoonPolicy,
      activePolicyCurrentMonth,
      totalSalesCurrentMonth,
      ThisFinancialYear,
      activePolicyThisFinancialYear,
      totalSalesThisFinancialYear,
      teamCountLoader,
      salesAmountLoader,
      claimCountLoader,
      unsoldPolicyLoader,
      activePolicyLoader,
      expirePolicyLoader,
      expiringSoonPolicyLoader,
      activePolicyCurrentMonthLoader,
      activePolicyThisFinancialYearLoader,
    } = this.state;

    return (
      <Main MessageRef={this.MessageRef}>
        {permissions?.center_coordinator || permissions?.aiqa_center ? (
          <AiqaCenter />
        ) : (
          <>
            {permissions?.universal_search && <UniversalSearch />}
            <AppRow>
              <div className="flex-auto">
                <div className="max-w-4xl">
                  <AppRow>
                    <div className="flex-1 pt-10">
                      <AppHeading className="mb-6">
                        Hello, {user.name}
                      </AppHeading>
                      <AppCard title={false} className="mb-6">
                        <div className="flex justify-between">
                          <img alt="" src={insurance} />
                          <div className="flex flex-col justify-between">
                            <AiqaIcon size={30} className="text-primary" />
                            {permissions?.application_add ? (
                              <Link to="/subscribe">
                                <Button
                                  size="sm"
                                  display="inline-block"
                                  title="Add New Member"
                                  className="px-6"
                                />
                              </Link>
                            ) : (
                              false
                            )}
                          </div>
                          <img
                            alt="shield"
                            className="shield-image"
                            src={shield}
                          />
                        </div>
                      </AppCard>
                    </div>
                  </AppRow>

                  <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
                    {permissions?.application_view && (
                      <div className="col-span-1">
                        <Link to="/subscriptions">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title="Active Subscription"
                            loader={activePolicyLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                              {this.getNumber(activePolicy)}
                            </AppHeading>
                          </AppCard>
                        </Link>
                      </div>
                    )}
                    {permissions?.expire_policy_view && expirePolicy > 0 && (
                      <div className="col-span-1">
                        <Link to="/expired">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title="Expired Subscription"
                            loader={expirePolicyLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                              {this.getNumber(expirePolicy)}
                            </AppHeading>
                          </AppCard>
                        </Link>
                      </div>
                    )}
                    {permissions?.expiring_soon_policy_view &&
                      expiringSoonPolicy > 0 && (
                        <div className="col-span-1">
                          <Link to="/expiring-soon">
                            <AppCard
                              className="cursor-pointer h-32 flex flex-col justify-between"
                              title="Subscriptions Expiring Soon"
                              loader={expiringSoonPolicyLoader}
                            >
                              <AppHeading color="primary">
                                <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                                {this.getNumber(expiringSoonPolicy)}
                              </AppHeading>
                            </AppCard>
                          </Link>
                        </div>
                      )}
                    {permissions?.unpaid_policy_view && unsoldPolicy > 0 && (
                      <div className="col-span-1">
                        <Link to="/leads">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title="Unpaid Subscription"
                            loader={unsoldPolicyLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                              {this.getNumber(unsoldPolicy)}
                            </AppHeading>
                          </AppCard>
                        </Link>
                      </div>
                    )}

                    {permissions && permissions?.sale_month_year_wise && (
                      <>
                        <div className="col-span-1">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title="Active Subscription (Current Month)"
                            loader={activePolicyCurrentMonthLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                              {this.getNumber(activePolicyCurrentMonth)}
                            </AppHeading>
                          </AppCard>
                        </div>

                        <div className="col-span-1">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title="Total Sales (Current Month)"
                            loader={activePolicyCurrentMonthLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faRupeeSign} />{" "}
                              {this.getNumber(totalSalesCurrentMonth)}
                            </AppHeading>
                          </AppCard>
                        </div>

                        <div className="col-span-1">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between"
                            title={`Active Subscription (FY ${ThisFinancialYear})`}
                            loader={activePolicyThisFinancialYearLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                              {this.getNumber(activePolicyThisFinancialYear)}
                            </AppHeading>
                          </AppCard>
                        </div>

                        <div className="col-span-1">
                          <AppCard
                            className="cursor-pointer h-32 flex flex-col justify-between  "
                            title={`Total Sales (FY ${ThisFinancialYear})`}
                            loader={activePolicyThisFinancialYearLoader}
                          >
                            <AppHeading color="primary">
                              <FontAwesomeIcon icon={faRupeeSign} />{" "}
                              {this.getNumber(totalSalesThisFinancialYear)}
                            </AppHeading>
                          </AppCard>
                        </div>
                      </>
                    )}

                    {permissions && permissions?.total_sale_view && (
                      <div className="col-span-1">
                        <AppCard
                          className="cursor-pointer h-32 flex flex-col justify-between"
                          title="Total Sales"
                          loader={salesAmountLoader}
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faRupeeSign} />{" "}
                            {this.getNumber(salesAmount)}
                          </AppHeading>
                        </AppCard>
                      </div>
                    )}
                  </div>
                  {products && products.length > 0 && (
                    <AppHeading className="mb-6 mt-6" size="xl" font="normal">
                      Our Products
                    </AppHeading>
                  )}

                  <ProductsList />
                </div>
              </div>
              <div className="flex-2">
                <div className="grid md:grid-cols-1 grid-cols-2 gap-2">
                  {permissions?.upload_excel ? (
                    <Link
                      to={{
                        pathname: permissions?.excel_uploader_history_table
                          ? "/excel-history"
                          : "/excel-uploader",
                        state: {
                          componentTo: "/excel-uploader",
                          type: "normal uploader",
                        },
                      }}
                    >
                      <div className="col-span-1 mt-3 w-400-px ">
                        <AppCard className="cursor-pointer" title="Uploader">
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileExcel} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.group_upload ? (
                    <Link
                      to={{
                        pathname: permissions?.excel_uploader_history_table
                          ? "/excel-history"
                          : "/group-excel-uploader",
                        state: {
                          componentTo: "/group-excel-uploader",
                          type: "group uploader",
                        },
                      }}
                    >
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Group Uploader"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileExcel} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.policy_uploader ? (
                    <Link to="/policy-uploader">
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Vendor Subscription Uploader"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileExcel} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.credit_upload ? (
                    <Link
                      to={{
                        pathname: permissions?.excel_uploader_history_table
                          ? "/excel-history"
                          : "/credit-life-uploader",
                        state: {
                          componentTo: "/credit-life-uploader",
                          type: "credit uploader",
                        },
                      }}
                    >
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Credit Life Uploader"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileExcel} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.emi_product_uploader ? (
                    <Link
                      to={{
                        pathname: permissions?.excel_uploader_history_table
                          ? "/excel-history"
                          : "/emi-uploader",
                        state: {
                          componentTo: "/emi-uploader",
                          type: "emi uploader",
                        },
                      }}
                    >
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="EMI Product Uploader"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileExcel} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.reseller_add ? (
                    <Link to="/employees">
                      <div
                        className="col-span-1 mt-3 w-400-px"
                        style={{ minWidth: 180 }}
                      >
                        <AppCard
                          className="cursor-pointer"
                          title="View Team"
                          loader={teamCountLoader}
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faUserFriends} />{" "}
                            {this.getNumber(teamCount)}
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}

                  {permissions?.claim_view ? (
                    <div className="col-span-1 mt-3 w-400-px">
                      <Link to="/reimbursements">
                        <AppCard
                          className="cursor-pointer"
                          title="Reimbursements"
                          loader={claimCountLoader}
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faUserMd} />{" "}
                            {this.getNumber(claimCount)}
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    false
                  )}
                  {checkReportPermission() && (
                    <div className="col-span-1 mt-3 w-400-px" style={{ minWidth: 180 }}>
                      <Link to="/reports">
                        <AppCard className="cursor-pointer" title="Reports">
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileArchive} />
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  )}
                  {permissions?.customer_lead_capture ? (
                    <div className="col-span-1 mt-3 w-400-px" style={{ minWidth: 180 }}>
                      <Link to="/lead-capture">
                        <AppCard
                          className="cursor-pointer"
                          title="Customer Lead Capture"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileArchive} />
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    false
                  )}
                  {permissions?.reseller_lead_capture ? (
                    <div className="col-span-1 mt-3 w-400-px" style={{ minWidth: 180 }}>
                      <Link to="/reseller-lead-capture">
                        <AppCard
                          className="cursor-pointer"
                          title="Reseller Lead Capture"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileArchive} />
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    false
                  )}
                  {permissions?.aiqa_center ? (
                    <div className="col-span-1 mt-3 w-400-px">
                      <Link to="/chemist">
                        <AppCard className="cursor-pointer" title="Chemist">
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {permissions?.reseller_document_view ? (
                    <div className="col-span-1 mt-3 w-400-px">
                      <Link to="/reseller-document-upload">
                        <AppCard
                          className="cursor-pointer"
                          title="Upload Document"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileArchive} />{" "}
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {permissions?.sales_dashboard ? (
                    <Link to="/previous-dashboard">
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Sale Dashboard version 1.0"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faDatabase} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.sales_dashboard ? (
                    <Link to="/sales-dashboard">
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Sale Dashboard version 2.0"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faDatabase} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                  {permissions?.inventory && (
                    <div className="col-span-1 mt-3 w-400-px">
                      <Link to="/inventory">
                        <AppCard
                          className="cursor-pointer"
                          title="Inventory"
                        >
                          <AppHeading color="primary">
                            <div className="flex justify-start">
                              <img
                                src={inventoryImg}
                                className="h-8 w-8"
                                alt="inventoryImg"
                              />
                            </div>
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  )}

                  {permissions?.serviceability_report ? (
                    <Link to="/serviceability-report">
                      <div className="col-span-1 mt-3 w-400-px">
                        <AppCard
                          className="cursor-pointer"
                          title="Serviceability Reports"
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faFileArchive} />
                          </AppHeading>
                        </AppCard>
                      </div>
                    </Link>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </AppRow>
          </>
        )}
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;

  return { loading: loading, user: user };
};

const HomeConnect = connect(mapStateToProps)((props) => {
  return <Home {...props} />;
});

export default withRouter(HomeConnect);
