export const excelUploaderLimit = 20001;
export const creditExcelUploaderLimit = 20001;
export const policyExcelUploaderLimit = 50001;
export const groupExcelUploaderLimit = 20001;
export const groupExcelUploaderFileSize = 4194304; // 4MB
export const inventoryExcelUploaderFileSize = 4194304; // 4MB
export const RESELLER_ENCRYPTION_KEY = "1234567887654321";
export const inventoryStockExcelUploaderLimit = 20001;

export const PURCHASE_STOCK = "purchase_stock";
export const TRANSFER_STOCK = "transfer_stock";

export const appointmentType = [
  "appointment_created",
  "pharmacist_joined_call",
  "doctor_joined_call",
  "aiqa_center_joined_call",
];

export const addTeamDocSize = 5 * 1024 * 1024;

export function getFileExtension(file) {
  return file?.name?.split(".").pop().toLowerCase();
}

export const extensions = ["pdf", "jpeg", "jpg", "png"];

export const profileExtension = ["jpeg", "jpg", "png"];
