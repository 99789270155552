import { memo } from "react";
import Spreadsheet from "react-spreadsheet";

const SpreadsheetViewer = ({
  columnLabels = false,
  rowLabels = false,
  data = [],
  setData,
}) => {
  return (
    <Spreadsheet
      data={data || []}
      columnLabels={columnLabels}
      rowLabels={rowLabels}
      onChange={setData}
    />
  );
};

export default memo(SpreadsheetViewer);
