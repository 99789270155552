import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ExampleComponent } from "./constant";
import SortIcon from "./SortIcon";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ReportTable = ({ reports, initialsValues, sort, setSort }) => {
  return (
    <>
      <div style={{ marginTop: 20 }} className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-x-auto">
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <tr>
                    {Array(4)
                      ?.fill(null)
                      ?.map((_, index) => {
                        return (
                          <th
                            className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left"
                            style={{ background: "#DB2228", color: "white" }}
                          ></th>
                        );
                      })}
                    {initialsValues?.externalFilters?.includes(
                      "Hospi cash"
                    ) && (
                      <th
                        className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                        colSpan={2}
                        style={{
                          background: "#DB2228",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Hospicash
                      </th>
                    )}
                    {initialsValues?.externalFilters?.includes(
                      "Accidental insurance"
                    ) && (
                      <th
                        className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                        colSpan={2}
                        style={{
                          background: "#DB2228",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Accidental
                      </th>
                    )}
                  </tr>
                  <tr>
                    <th
                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border sticky left-0 bg-white"
                      style={{ background: "#DB2228", color: "white" }}
                    >
                      #
                    </th>
                    <th
                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border sticky left-[40px]"
                      style={{ background: "#DB2228", color: "white" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <span>Reseller name</span>
                        {/* <SortIcon
                          label="resellerName"
                          sort={sort}
                          setSort={setSort}
                        /> */}
                      </div>
                    </th>

                    <th
                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                      style={{ background: "#DB2228", color: "white" }}
                    >
                      Product name
                    </th>
                    <th
                      className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                      style={{ background: "#DB2228", color: "white" }}
                    >
                      count
                    </th>
                    {Array(initialsValues?.externalFilters?.length)
                      ?.fill(null)
                      ?.map((_, index) => {
                        return (
                          <>
                            <th
                              className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                              style={{ background: "#DB2228", color: "white" }}
                            >
                              Eligible data
                            </th>
                            <th
                              className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left border"
                              style={{ background: "#DB2228", color: "white" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>Activation data</span>
                                {/* <SortIcon
                                  label={`${initialsValues.externalFilters[index]}.activationData`}
                                  sort={sort}
                                  setSort={setSort}
                                /> */}
                              </div>
                            </th>
                          </>
                        );
                      })}
                  </tr>
                </thead>
                {reports?.map((record, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td
                          className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border sticky left-0 bg-white"
                          style={{ zIndex: 1 }}
                          rowspan={record?.productDtos?.length + 1}
                        >
                          {index + 1}
                        </td>

                        <td
                          className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border sticky left-[40px] bg-white"
                          style={{ zIndex: 1 }}
                          rowspan={record?.productDtos?.length + 1}
                        >
                          {record.resellerName}
                        </td>
                      </tr>
                      {record &&
                        record?.productDtos?.length > 0 &&
                        record?.productDtos?.map((item, index) => {
                          const { hospiCashDtos, acDtos } =
                            item.productBenefits;
                          return (
                            <tr>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border">
                                <HtmlTooltip
                                  arrow
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        External Product Benefits
                                      </p>
                                      <ol
                                        style={{
                                          listStyleType: "decimal",
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        {item?.benefits?.map(
                                          (benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                          )
                                        )}
                                      </ol>
                                    </React.Fragment>
                                  }
                                >
                                  <span>{item.productName}</span>
                                </HtmlTooltip>
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border">
                                {item.count}
                              </td>
                              {initialsValues.externalFilters.includes(
                                "Hospi cash"
                              ) && (
                                <>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border">
                                    {hospiCashDtos.length > 0
                                      ? hospiCashDtos?.[0]?.eligibleData ?? 0
                                      : "N/A"}
                                  </td>

                                  <ExampleComponent
                                    data={hospiCashDtos}
                                    item={item}
                                  />
                                </>
                              )}
                              {initialsValues.externalFilters.includes(
                                "Accidental insurance"
                              ) && (
                                <>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border">
                                    <Tooltip
                                      title={`multiplty by ${acDtos?.[0]?.maxMemberCovered} member covered`}
                                      arrow
                                      placement="left"
                                    >
                                      <span>
                                        {acDtos.length > 0
                                          ? acDtos?.[0]?.eligibleData ?? 0
                                          : "N/A"}
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <ExampleComponent data={acDtos} item={item} />
                                </>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  );
                })}
                {reports.length === 0 ? (
                  <tr>
                    <td
                      colSpan="9"
                      height="50"
                      className="text-red-500 font-light text-center"
                    >
                      Your result is empty
                    </td>
                  </tr>
                ) : (
                  false
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportTable;
