import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { AppHeading } from "../../components/AppComponents";
import {
  JavaApiGet,
  getResellerEncryptionToken,
  getApiForDownloadCSVSalesReport,
  JavaApiPost,
  enrollmentGetApi,
} from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import { Pagination, Modal } from "rsuite";
import Tooltip from "@mui/material/Tooltip";
import { DownloadIcon, EyeIcon } from "@heroicons/react/outline";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { toast } from "react-toastify";
import { InputGroup } from "../../components/form/InputGroup";
import LinearProgress from "@mui/material/LinearProgress";
import CachedIcon from "@mui/icons-material/Cached";
import { csvToJson, downloadExcelFile } from "../../utils/commom";
import SpreadsheetViewer from "../../components/spreadsheet/index";

const validateEmail = (email) => {
  if (typeof email !== "string") {
    return false; // Ensure that the input is a string
  }
  return email.match(
    /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

class SalesDownloadReport extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    count: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    downloadReports: [],
    activeTab: "downloadReports",
    params: {
      page: 0,
      email: [],
      emailReportId: "",
      sortBy: "createdAt",
      sequenceType: {
        reportName: "DESC",
        createdAt: "DESC",
        expireAt: "DESC",
      },
    },
    errors: {},
    showModal: false,
    excelData: false,
    reportName: "",
    modalSize: window.innerWidth <= 768 ? "xs" : "sm",
  };

  render() {
    const {
      downloadReports,
      showModal,
      errors,
      params,
      excelData,
      reportName,
      modalSize,
    } = this.state;

    return (
      <div>
        <AppHeading className="mb-10 text-center" style={{ color: "#DB2228" }}>
          {this.props.title}
        </AppHeading>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <table className="min-w-full border" id="table-to-xls">
              <thead className="border-b">
                <tr>
                  {this.props.isShowField && (
                    <th className="text-sm text-center font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                      Report name
                      {downloadReports.length > 0 && (
                        <Tooltip
                          title={
                            this?.state?.params?.sequenceType?.reportName ===
                            "DESC"
                              ? "Ascending"
                              : "Descending"
                          }
                          arrow
                        >
                          <span
                            onClick={() =>
                              this.handleToggleSortDirection("reportName")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            {this?.state?.params?.sequenceType?.reportName ===
                            "DESC"
                              ? "▼"
                              : "▲"}
                          </span>
                        </Tooltip>
                      )}
                    </th>
                  )}
                  <th className="text-sm text-left font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                    File name
                  </th>
                  <th className="text-sm text-center font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                    <Tooltip title="Date of file download" arrow>
                      <span>Created at</span>
                    </Tooltip>
                    {downloadReports.length > 0 && (
                      <Tooltip
                        title={
                          this?.state?.params?.sequenceType?.createdAt ===
                          "DESC"
                            ? "Ascending"
                            : "Descending"
                        }
                        arrow
                      >
                        <span
                          onClick={() =>
                            this.handleToggleSortDirection("createdAt")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {this?.state?.params?.sequenceType?.createdAt ===
                          "DESC"
                            ? "▼"
                            : "▲"}
                        </span>
                      </Tooltip>
                    )}
                  </th>
                  <th className="text-sm text-center font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                    <Tooltip
                      title="Files can be downloaded up to 15 days"
                      arrow
                    >
                      <span>Expiring on</span>
                    </Tooltip>
                    {downloadReports.length > 0 && (
                      <Tooltip
                        title={
                          this?.state?.params?.sequenceType?.expireAt === "DESC"
                            ? "Ascending"
                            : "Descending"
                        }
                        arrow
                      >
                        <span
                          onClick={() =>
                            this.handleToggleSortDirection("expireAt")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {this?.state?.params?.sequenceType?.expireAt ===
                          "DESC"
                            ? "▼"
                            : "▲"}
                        </span>
                      </Tooltip>
                    )}
                  </th>
                  <th className="text-sm text-center font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                    Status
                  </th>
                  <th className="text-sm text-center font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {downloadReports?.length ? (
                  downloadReports?.map((data, index) => {
                    return (
                      <tr className="border-b" key={index}>
                        {this.props.isShowField && (
                          <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {data?.reportName?.toLowerCase()}
                          </td>
                        )}
                        <td className="text-left border-r px-4 py-2 font-small dark:border-neutral-500">
                          {data?.parentResellerName &&
                            `${data.parentResellerName}_`}
                          {data?.childResellerName &&
                            `${data.childResellerName}_`}
                          {data?.startDate &&
                            `${moment(data.startDate).format(
                              "DD/MM/YYYY"
                            )}_to_`}
                          {data?.endDate &&
                            moment(data.endDate).format("DD/MM/YYYY")}
                          <br />
                          <span>
                            {data?.filterValue ? (
                              (() => {
                                const parsedFilter = JSON.parse(
                                  data.filterValue
                                );

                                return (
                                  <div className="text-gray-500 text-sm">
                                    Start date:{" "}
                                    {(parsedFilter?.startDate &&
                                      moment(parsedFilter.startDate).format(
                                        "DD/MM/YYYY"
                                      )) ||
                                      "N/A"}
                                    , End date:{" "}
                                    {(parsedFilter?.endDate &&
                                      moment(parsedFilter.endDate).format(
                                        "DD/MM/YYYY"
                                      )) ||
                                      "N/A"}
                                    , Date type:{" "}
                                    {parsedFilter?.dateType?.replace(
                                      "_",
                                      " "
                                    ) || "N/A"}
                                  </div>
                                );
                              })()
                            ) : (
                              <div>No filter value available</div>
                            )}
                          </span>
                        </td>
                        <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500">
                          {data?.createdAt &&
                            moment(data?.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500">
                          {data?.expireAt &&
                            moment(data?.expireAt).format("DD/MM/YYYY")}
                        </td>
                        <td
                          className={`text-center border-r px-4 py-2 font-small dark:border-neutral-500 capitalize ${
                            data?.status?.toLowerCase() === "pending"
                              ? "text-blue-500"
                              : data?.status?.toLowerCase() === "completed"
                              ? "text-green-500"
                              : data?.status?.toLowerCase() === "failed"
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {data?.status?.toLowerCase()}
                        </td>

                        <td className="text-center border-r px-4 py-2 font-small dark:border-neutral-500">
                          {data?.status?.toLowerCase() === "completed" ? (
                            <div className="flex items-center justify-center">
                              {/* {data?.count < 1000 && (
                                <Tooltip
                                  title={`Preview ${data?.reportName}`}
                                  arrow
                                >
                                  <button
                                    onClick={() =>
                                      this.downloadReport(
                                        data?.id,
                                        data?.reportName,
                                        "view"
                                      )
                                    }
                                  >
                                    <EyeIcon
                                      color="blue"
                                      height="25px"
                                      width="50px"
                                    />
                                  </button>
                                </Tooltip>
                              )} */}
                              <Tooltip title="Download" arrow>
                                <button
                                  onClick={() =>
                                    this.downloadReport(
                                      data?.id,
                                      data?.reportName?.toLowerCase(),
                                      "download"
                                    )
                                  }
                                >
                                  <DownloadIcon
                                    color="green"
                                    height="25px"
                                    width="50px"
                                  />
                                </button>
                              </Tooltip>
                              <Tooltip title="Share by email" arrow>
                                <button
                                  onClick={() => this.sendEmail(data?.id)}
                                >
                                  <ForwardToInboxOutlinedIcon
                                    sx={{
                                      color: "#DB2228",
                                      cursor: "pointer",
                                      height: "25px",
                                      width: "50px",
                                    }}
                                  />
                                </button>
                              </Tooltip>
                              <Tooltip title="Refresh data" arrow>
                                <button
                                  onClick={() =>
                                    this.refreshExcelData(data?.id)
                                  }
                                >
                                  <CachedIcon
                                    sx={{
                                      height: "25px",
                                      width: "50px",
                                    }}
                                  />
                                </button>
                              </Tooltip>
                            </div>
                          ) : data?.status?.toLowerCase() === "pending" ? (
                            <div className="flex items-center justify-center">
                              <button style={{ width: "100px" }}>
                                <Tooltip title="Preparing">
                                  <LinearProgress
                                    color="inherit"
                                    sx={{ color: "#DB2228" }}
                                  />
                                </Tooltip>
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center">
                              <Tooltip title="Refresh data" arrow>
                                <button
                                  onClick={() =>
                                    this.refreshExcelData(data?.id)
                                  }
                                >
                                  <CachedIcon
                                    sx={{
                                      height: "25px",
                                      width: "50px",
                                    }}
                                  />
                                </button>
                              </Tooltip>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={this.props.isShowField ? "6" : "5"}
                      className="text-red-500 font-large text-center"
                      height="150"
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={2}
              size="xs"
              layout={this.state.layout}
              total={this.state.count}
              limit={this.state.limit}
              activePage={this.state.params.page + 1}
              onChangePage={this.onPageChange}
            />
          </div>
        </div>

        <Modal
          open={showModal}
          onClose={this.handleCloseModal}
          backdrop="static"
          size={modalSize}
          className={`text-center ${modalSize ? "top-1/3" : "top-52"}`}
        >
          <Modal.Body>
            <div className="pb-4">
              Please provide the email address where you would like to receive
              this report.
            </div>
            <div className="mb-5 w-3/4 mx-auto">
              <InputGroup
                error={errors.email}
                //   label="Email"
                type="email"
                placeholder="Enter email address here"
                attrs={{
                  value: this?.state?.params?.email,
                  onChange: (e) => this.onChangeHandler(e, "email"),
                  onKeyPress: (e) => this.handleKeyPress(e),
                }}
              />
            </div>
            <div className="pb-4 text-sm">
              <span className="font-bold">Note: </span>Maximum of two email IDs,
              each separated by a comma ( , )
            </div>
            <div className="flex justify-center gap-5">
              <button
                type="button"
                className={`bg-red-500 text-white py-2 px-4 rounded-xl mt-2 border-2 border-red-500 ${
                  !this.state.params.email ||
                  this.state.params.email.length === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-red-600 focus:ring focus:ring-red-500"
                }`}
                onClick={this.handleSendEmail}
                disabled={
                  !this.state.params.email ||
                  this.state.params.email.length === 0
                }
                aria-label="Send"
              >
                Send
              </button>
              <button
                type="button"
                className="bg-white-500 text-red py-2 px-4 rounded-xl mt-2 border-2 border-red-500"
                onClick={this.handleCloseModal}
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          open={excelData}
          onClose={this.handleCloseExcelModal}
          backdrop="static"
          size="full"
        >
          <div className="pb-2">
            <div className="capitalize flex justify-center">
              {reportName.toLowerCase()}
            </div>
          </div>
          <Modal.Body>
            <SpreadsheetViewer data={excelData} />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end mt-2">
              <button
                type="button"
                className=" bg-red-500 text-white py-2 px-4 rounded-xl hover:bg-red-500 focus:ring focus:ring-red-500 mt-2"
                onClick={this.handleCloseExcelModal}
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleCloseExcelModal = () => {
    this.setState((prev) => ({ ...prev, excelData: false }));
    this.setState((prev) => ({ ...prev, reportName: "" }));
  };

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState(
      { params: { ...this.state.params, page: page - 1 } },
      this.getDownloadReportsData
    );
  };

  componentDidMount() {
    this.getDownloadReportsData();
    this.pollInterval = setInterval(this.checkPendingReports, 10000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateDownloadList !== this.props.updateDownloadList) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  checkPendingReports = () => {
    const { downloadReports } = this.state;
    const hasPendingReports = downloadReports.some(
      (report) => report?.status?.toLowerCase() === "pending"
    );

    if (hasPendingReports) {
      this.getDownloadReportsData();
    }
  };

  refreshExcelData = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await enrollmentGetApi(
      `enrollment-service/excel_report/refresh_excel_data/${id}`
    );

    if (response?.status === "OK") {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
    this.getDownloadReportsData();
  };

  handleClick = () => {
    this.onPageChange(0);
  };

  handleToggleSortDirection = (field) => {
    this.setState(
      (prevState) => {
        const currentSequenceType = prevState.params.sequenceType[field];
        return {
          params: {
            ...prevState.params,
            sequenceType: {
              ...prevState.params.sequenceType,
              [field]: currentSequenceType === "ASC" ? "DESC" : "ASC",
            },
            sortBy: field,
          },
        };
      },
      () => {
        this.getDownloadReportsData(field);
      }
    );
  };

  getDownloadReportsData = async (field) => {
    const { limit, params } = this.state;
    const sequenceType =
      params.sequenceType[field] || params.sequenceType.createdAt;
    let payload = {
      size: limit,
      page: params?.page,
      reportName: this.props.reportName,
      sortBy: params?.sortBy,
      sequenceType: sequenceType,
    };
    // AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await JavaApiGet(
      "enrollment-service/excel_report/record",
      payload,
      false,
      getResellerEncryptionToken()
    );

    if (response.status === "OK") {
      this.setState({
        downloadReports: response?.responseObject || [],
        count: response?.count || 0,
      });
    } else {
      this.setState({
        downloadReports: [],
      });
      toast.error("Failed to fetch data");
    }
    // AppStore.dispatch({ type: "LOADING", loading: false });
  };

  downloadReport = async (id, reportName, type) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await getApiForDownloadCSVSalesReport(
      `enrollment-service/excel_report/download_excel/${id}`
    );

    if (type === "view") {
      const arrayBuffer = await response.arrayBuffer(response);
      const text = new TextDecoder().decode(arrayBuffer);

      const jsonData = csvToJson(text);
      const headers = Object.keys(jsonData[0]).map((key) => ({
        value: key,
        readOnly: true,
      }));
      const rows = jsonData.map((item) =>
        Object.values(item).map((value) => ({ value, readOnly: true }))
      );
      const spreadsheetData = [headers, ...rows];
      this.setState((prev) => ({ ...prev, excelData: spreadsheetData }));
      this.setState((prev) => ({ ...prev, reportName: reportName }));
    } else {
      const blob = await response.blob();
      downloadExcelFile(blob, "downloadReport.csv");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      params: { ...this.state.params, email: "", emailReportId: "" },
    });
  };

  onChangeHandler = (e, key) => {
    const emailInput = `${e.target.value}`.replace(/\s/g, "");
    this.setState({
      params: { ...this.state.params, [key]: emailInput },
    });
  };

  sendEmail = (id) => {
    this.setState({
      showModal: true,
      params: { ...this.state.params, emailReportId: id },
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSendEmail();
    }
  };

  checkEmailValid = (email) => {
    if (email) {
      const invalidEmails = email.split(",");
      const notValidEmail = invalidEmails.filter(
        (email) => !validateEmail(email)
      );

      if (invalidEmails.length > 2) {
        toast.error("Only two email addresses are allowed.");
        return false;
      }
      if (notValidEmail.length > 0) {
        toast.error("Invalid email address");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  handleSendEmail = async () => {
    const { params } = this.state;

    const validate = this.checkEmailValid(params?.email);

    if (!validate) {
      return;
    }

    let payload = {
      email: params?.email,
      excelReportId: params?.emailReportId,
    };

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await JavaApiPost(
      "enrollment-service/excel_report/send_mail",
      payload,
      false,
      getResellerEncryptionToken()
    );

    if (response?.code === 200) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
    this.handleCloseModal();
  };
}

const mapStateToProps = (state) => {
  const { loading, updateDownloadList } = state;
  return { loading: loading, updateDownloadList };
};

const SalesReportConnect = connect(mapStateToProps)((props) => {
  return <SalesDownloadReport {...props} />;
});

export default withRouter(SalesReportConnect);
