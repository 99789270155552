import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "../Main";
import SalesDownloadReport from "../reports/SalesDownloadReport";
import { AppBackButton } from "../../components/AppComponents";

class DownloadedReports extends Component {
  MessageRef = React.createRef();

  render() {
    return (
      <Main MessageRef={this.MessageRef}>
        <div>
          <AppBackButton to="/">Back</AppBackButton>
          <SalesDownloadReport
            title="Download Center"
            reportName=""
            isShowField
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const DownloadedReportsConnect = connect(mapStateToProps)((props) => {
  return <DownloadedReports {...props} />;
});

export default withRouter(DownloadedReportsConnect);
