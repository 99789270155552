import { Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import { Button } from "rsuite";

function ReportTable({
  data,
  onChangeBatchId,
  sort,
  handleSort,
  onClickTenure,
}) {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <table className="min-w-full border" id="table-to-xls">
          <thead className="border-b">
            <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
              Sr.No
            </th>
            <th className="text-sm text-left font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
              Reseller name
            </th>
            <th className="text-sm text-left font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
              Batch Id
            </th>

            <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
              Total count
            </th>
            <th className="text-sm text-left font-large px-4 py-4 whitespace-nowrap bg-red-500 text-white">
              Uploaded date
              <Tooltip
                title={`sort by ${
                  sort === "ASC" ? "descending order" : "ascending order"
                }`}
                arrow
                placement="right"
              >
                <span>
                  {sort === "DESC" ? (
                    <ArrowUpwardIcon
                      fontSize="medium"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("ASC")}
                    />
                  ) : sort === null ? (
                    <ArrowDownwardIcon
                      fontSize="medium"
                      style={{
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                      onClick={() => handleSort("DESC")}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      fontSize="medium"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("DESC")}
                    />
                  )}
                </span>
              </Tooltip>
            </th>
            <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
              Success count
            </th>
            <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
              Fail count
            </th>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((item, index) => {
                return (
                  <tr className="border-b" key={index}>
                    <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {index + 1}
                    </td>
                    <td className="text-left px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {item?.resellerName || "---"}
                    </td>
                    <td
                      className="text-left px-4 py-2 font-small dark:border-neutral-500 capitalize"
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <Tooltip
                        title="Click to check the breakdown"
                        placement="left"
                        arrow
                      >
                        <span onClick={() => onChangeBatchId(item.batchId)}>
                          {item?.batchId || "---"}
                        </span>
                      </Tooltip>
                    </td>

                    <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {item?.totalRecord || "---"}
                    </td>
                    <td className="text-left px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {item?.uploadedStartTime || "---"}
                    </td>
                    <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {item?.successRecord ? (
                        <span
                          className="px-3 py-2 rounded-md text-white font-medium"
                          style={{ background: "green" }}
                        >
                          {item?.successRecord}
                        </span>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                      {item?.failRecord ? (
                        <span className="px-3 py-2 rounded-md text-white font-medium bg-red-600">
                          {item?.failRecord}
                        </span>
                      ) : (
                        "---"
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="7"
                  height="150"
                  className="text-red-500 font-large text-center"
                >
                  No Data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportTable;
