import React, { useEffect, useRef, useState } from "react";
import Main from "../../Main";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import {
  JavaApiGet,
  JavaApiPost,
  enrollmentPostApi,
  getResellerId,
} from "../../../lib/AppHelper";
import CustomSelect from "../../../components/CustomSelect";
import { InputDate } from "../../../components/form/InputDate";
import { DateFilter, LastWeekDates, WeekDates } from "../ReportMaster/constant";
import moment from "moment";
import useMobileDetect from "../../../utils/useMobileDetect";
import { Pagination } from "rsuite";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";
import ReportModal from "./ReportModal";
import ReportTable from "./ReportTable";
import InfintyDropdown from "../../StepClaimForm/InfintyDropdown";
import { RefreshIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

const Index = () => {
  const userInfo = useSelector((state) => state.user);
  const MessageRef = useRef();
  const userRef = useRef(null || userInfo?.name);
  const { isMobile } = useMobileDetect();
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
    sequenceType: "ASC",
    reseller: { resellerId: getResellerId(), resellerName: userInfo.name },
  });
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(null);
  const [breakdownData, setBreakdownData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tenureData, setTenureData] = useState([]);

  const fetchData = async (pg) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      resellerId: filter.reseller.resellerId,
      batchId: "",
      startDate: moment(filter?.from).format("YYYY-MM-DD"),
      endDate: moment(filter?.to).format("YYYY-MM-DD"),
      page: pg ? pg - 1 : page - 1,
      size: 10,
      sequenceType: filter?.sequenceType,
    };
    try {
      const response = await JavaApiPost(
        "enrollment-service/uploader/api/batch_details",
        data
      );
      if (response.code === 200) {
        setTableData(response.responseObject);
        setTotalData(response.count);
        AppStore.dispatch({ type: "LOADING", loading: false });
      } else {
        setTableData(null);
        setTotalData(0);
        AppStore.dispatch({ type: "LOADING", loading: false });
      }
    } catch (error) {
      toast.error(error.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  function onPageChange(pg) {
    setPage(pg);
    fetchData(pg);
  }

  useEffect(() => {
    if (filter?.from && filter?.to && filter.reseller.resellerId) fetchData();
  }, [
    filter?.from,
    filter?.to,
    filter.sequenceType,
    filter.reseller.resellerId,
  ]);

  function handleSort(sorting) {
    if (tableData === null) return;
    setFilter({ ...filter, sequenceType: sorting });
  }
  const fetchBreakdownData = async (id) => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/uploader/api/batch_wise_details/${id}`
      );
      if (response.code === 200) {
        setBreakdownData(response.responseObject);
      } else {
        setBreakdownData([]);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const fetchTenureData = async (id) => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/uploader/tenure_wise_policy_count?batchId=${id}`
      );
      if (response.code === 200) {
        setTenureData(response.responseObject);
      } else {
        setTenureData([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const fetchDataModal = async (id) => {
    setModalOpen(id);
    setLoading(true);
    try {
      await Promise.all([fetchBreakdownData(id), fetchTenureData(id)]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  function handleClickReset() {
    setFilter({
      duration: "thisMonth",
      from: WeekDates("month")[0],
      to: WeekDates("month")[1],
      sequenceType: "ASC",
      reseller: { resellerId: getResellerId(), resellerName: userInfo?.name },
    });
    userRef.current = userInfo?.name;
  }

  return (
    <Main MessageRef={MessageRef}>
      <div className="grid md:grid-cols-2 gap-2 mb-xs">
        <div>
          <AppBackButton to="/">Back</AppBackButton>
        </div>
      </div>
      <div className="flex flex-wrap justify-center mb-5 mt-0">
        <AppHeading color="red" size="2xl">
          Report 360
        </AppHeading>
      </div>
      <div className="flex items-center justify-between">
        <div
          className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-6"
          style={{ marginBottom: "30px" }}
        >
          <CustomSelect
            label="Date filter"
            value={filter.duration}
            onChange={(e) => {
              let value = e.target.value;
              let dates;
              if (value === "custom") {
                setFilter((state) => ({
                  ...state,
                  duration: value,
                  from: null,
                  to: null,
                }));
                return;
              }
              if (value === "today") dates = WeekDates("day");
              if (value === "yesterday") dates = LastWeekDates("day");
              if (value === "thisWeek") dates = WeekDates("week");
              if (value === "previousWeek") dates = LastWeekDates("week");
              if (value === "thisMonth") dates = WeekDates("month");
              if (value === "previousMonth") dates = LastWeekDates("month");
              setFilter((state) => ({
                ...state,
                from: new Date(dates[0]),
                to: new Date(dates[1]),
                duration: value,
              }));
            }}
            data={DateFilter}
            style={{ width: isMobile() ? "300px" : "230px" }}
          />
          {filter.duration === "custom" && (
            <>
              <div>
                <InputDate
                  label="From"
                  placeholder="from"
                  maxDate={new Date(moment().add(1, "d").format("YYYY-MM-DD"))}
                  attrs={{
                    onChange: (e) => setFilter({ ...filter, from: e }),
                    value: filter?.from ? new Date(filter.from) : null,
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  maxDate={new Date(moment().add(1, "d").format("YYYY-MM-DD"))}
                  minDate={filter?.from && new Date(filter.from)}
                  attrs={{
                    onChange: (e) => setFilter({ ...filter, to: e }),
                    value: filter?.to ? new Date(filter.to) : null,
                    readOnly: !filter?.from,
                  }}
                />
              </div>
            </>
          )}
          <div style={{ marginTop: "-7px" }}>
            <InfintyDropdown
              label="Reseller name"
              request={enrollmentPostApi}
              endpoint={"enrollment-service/reseller/searchResellerName"}
              attrs={{
                searchParam: "searchKey",
                value: filter.reseller.resellerId || "",
              }}
              valueField="id"
              labelField="name"
              isCleanable={false}
              setValue={(data, item) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  reseller: {
                    ...prevFilter.reseller,
                    resellerId: item?.id,
                    resellerName: item?.label,
                  },
                }));
              }}
              customRenderMenu={(value, obj, menu, index) => {
                return (
                  <div
                    key={index}
                    className="p-4 text-xs space-y-2 border-b-2 cursor-pointer hover:bg-red-500 hover:text-white"
                    style={{ width: "100%" }}
                    onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="font-bold text-sm">{value}</div>
                    </div>
                    <div className="flex items-center justify-between">
                      {obj?.mobile && (
                        <div>
                          Mobile:{" "}
                          <span className="font-medium">{obj.mobile}</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
              searchId={filter.resellerId}
            />
          </div>
          <div className="pt-8">
            <Tooltip title="Reset filter" arrow>
              <button
                onClick={handleClickReset}
                className="text-white p-1 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
              >
                <RefreshIcon width={25} className="m-1" />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <div style={{ fontSize: "14px", fontWeight: "600" }}>
          {filter.from &&
            filter.to &&
            `Data from ${moment(filter?.from).format("YYYY-MM-DD")} to ${moment(
              filter?.to
            ).format("YYYY-MM-DD")}`}
          &nbsp;
        </div>
      </div>
      <ReportTable
        data={tableData}
        onChangeBatchId={(id) => fetchDataModal(id)}
        sort={filter.sequenceType}
        handleSort={handleSort}
        onClickTenure={(id) => fetchTenureData(id)}
      />
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={2}
          size="xs"
          layout={["total", "-", "pager", "skip"]}
          total={totalData}
          limit={10}
          activePage={page}
          onChangePage={(pg) => onPageChange(pg)}
        />
      </div>
      <ReportModal
        open={modalOpen}
        handleClose={() => {
          setModalOpen(null);
        }}
        breakdowndata={breakdownData}
        tenuredata={tenureData}
        loading={loading}
      />
    </Main>
  );
};

export default Index;
